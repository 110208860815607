<template>
  <!-- <transition name="todo" appear> -->
    <li>
      <label>
        <!--修改了props-->
        <!-- <input v-model="todo.done" type="checkbox"/> -->
        <input type="checkbox" @change="checkTodo(todo.id)">
        <span v-show="!todo.isEdit">{{todo.title}}</span>
        <input 
          v-show="todo.isEdit" 
          type="text" 
          :value="todo.title"
          @blur="handleBlur(todo, $event)"
          ref="inputTitle"
          >
      </label>
      <button class="btn btn-danger" @click="handleDelete(todo.id)">删除</button>
      <button v-show="!todo.isEdit" class="btn btn-edit" @click="handelEdit(todo)" >编辑</button>
    </li>
  <!-- </transition> -->
</template>

<script>
  import pubsub from 'pubsub-js'
  export default {
      name: 'TodoItem',
      methods: {
        checkTodo(id) {
          this.$bus.$emit('checkTodo', id);
        },
        handelEdit(todo) {
          if (Object.prototype.hasOwnProperty.call(todo, 'isEdit')) {
            todo.isEdit = true
          } else {
            this.$set(todo, 'isEdit', true);
          }

          // 下一次DOM更新完成后调用
          this.$nextTick(function() {
            this.$refs.inputTitle.focus();
          })
        },
        handleDelete(id) {
          if (confirm("确认删除吗？")) {
            // this.deleteTodo(id);
            // this.$bus.$emit('deleteTodo', id);
            pubsub.publish('deleteTodo', id);
          }
        },
        handleBlur(todo, e) {
          todo.isEdit = false;
          if (!e.target.value.trim()) {
            alert("输入不能为空");
            return;
          }
          this.$bus.$emit('updateTodo', todo.id, e.target.value);
        }
      },
      props: ['todo']
  }
</script>

<style scoped>

  /*item*/
  li {
    list-style: none;
    height: 36px;
    line-height: 36px;
    padding: 0 5px;
    border-bottom: 1px solid #ddd;
  }

  li label {
    float: left;
    cursor: pointer;
  }

  li label li input {
    vertical-align: middle;
    margin-right: 6px;
    position: relative;
    top: -1px;
  }

  li button {
    float: right;
    display: none;
    margin-top: 3px;
  }

  li:before {
    content: initial;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #ddd;
  }

  li:hover button{
    display: block;
  }

  .todo-enter-active {
    animation: showAnimation linear 0.5s;
  }

  .todo-leave-active {
    animation: showAnimation linear 0.5s reverse;
  }

  @keyframes showAnimation {
      from {
          transform: translateX(100%);
      }
      to {
          transform: translateX(0px);
      }
  }
</style>