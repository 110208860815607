<template>
  <div>
    <div class="todo-footer" v-show="todoTotal">
      <label>
        <!-- <input type="checkbox" :checked="allChecked" @change="checkAll" /> -->
        <input type="checkbox" v-model="allChecked" />
      </label>
      <span>
        <span>已完成{{doneTotal}}</span> / 全部{{todoTotal}}
      </span>
      <button class="btn btn-danger" @click="removeDone">清除已完成任务</button>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'TodoFooter',
        computed: {
          todoTotal() {
            return this.todos.length;
          },
          doneTotal() {
            // return this.todos.reduce((pre, current) => {
            //   return pre + (current.done ? 1 : 0);
            // }, 0);
            return this.todos.reduce((pre, current) => pre + (current.done ? 1 : 0), 0);
          },
          allChecked: {
            get() {
              return this.doneTotal === this.todoTotal && this.todoTotal > 0;
            },
            set(value) {
              this.$emit('checkAllTodos', value);
            }
          }
        },
        methods: {
          checkAll(e) {
            this.$emit('checkAllTodos', e.target.checked);
          },
          removeDone() {
            this.$emit('cleanAllDoneTodos');
          }
        },
        props: ['todos']
    }
</script>

<style scoped>
  /*footer*/
  .todo-footer {
    height: 40px;
    line-height: 40px;
    padding-left: 6px;
    margin-top: 5px;
  }

  .todo-footer label {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }

  .todo-footer label input {
    position: relative;
    top: -1px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .todo-footer button {
    float: right;
    margin-top: 5px;
  }
</style>