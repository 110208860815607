<template>
  <div id="app">
    <div class="todo-container">
      <div class="todo-wrap">
        <TodoHeader @addTodo="addTodo" />
        <TodoList :todos="todos" :deleteTodo="deleteTodo"/>
        <TodoFooter :todos="todos" @checkAllTodos="checkAllTodos" @cleanAllDoneTodos="cleanAllDoneTodos" />
      </div>
    </div>
  </div>
</template>

<script>
  import pubsub from 'pubsub-js'
  import TodoHeader from './components/TodoHeader.vue'
  import TodoList from './components/TodoList.vue'
  import TodoFooter from './components/TodoFooter.vue'

  export default {
    name: 'App',
    components: {
      TodoHeader,
      TodoList,
      TodoFooter,
    },
    data() {
      return {
        todos: JSON.parse(localStorage.getItem('todos')) ?? []
      }
    },
    methods: {
      addTodo(x) {
        this.todos.unshift(x);
      },
      checkTodo(id) {
        this.todos.forEach((todo) => {
          if (todo.id === id) {
            todo.done = !todo.done;
          }
        })
      },
      updateTodo(id, title) {
        this.todos.forEach((todo) => {
          if (todo.id === id) {
            todo.title = title;
          }
        });
      },
      deleteTodo(_, id) {
        this.todos = this.todos.filter(todo => todo.id !== id);
      },
      checkAllTodos(done) {
        this.todos.forEach((todo) => {
          todo.done = done;
        });
      },
      cleanAllDoneTodos() {
        this.todos = this.todos.filter(todo => !todo.done) || [];
      }
    },
    watch: {
      todos: {
        deep: true, // 开启深度监视
        handler(value) {
          localStorage.setItem('todos', JSON.stringify(value));
        }
      }
    },
    mounted() {
      this.$bus.$on('checkTodo', this.checkTodo);
      // this.$bus.$on('deleteTodo', this.deleteTodo);
      this.$bus.$on('updateTodo', this.updateTodo);
      this.deleteTodoId = pubsub.subscribe('deleteTodo', this.deleteTodo);

    },
    beforeDestroy() {
      this.$bus.$off('checkTodo');
      // this.$bus.$off('deleteTodo');
      this.$bus.$off('updateTodo');
      pubsub.unsubscribe(this.deleteTodoId);
    }
  }
</script>

<style>
  /*base*/
  body {
    background: #fff;
  }

  .btn {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .btn-danger {
    color: #fff;
    background-color: #da4f49;
    border: 1px solid #bd362f;
  }

  .btn-edit {
    color: white;
    background-color: skyblue;
    border: 1px solid rgb(83, 198, 243);
    margin-right: 5px;
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #bd362f;
  }

  .btn:focus {
    outline: none;
  }

  .todo-container {
    width: 600px;
    margin: 0 auto;
  }
  .todo-container .todo-wrap {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

</style>
